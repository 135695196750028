import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import { sync } from "vuex-router-sync";
import store from "@/store";
import Cookies from "js-cookie";
import adminRoutes from "./admin";

// The middleware for every page of the application.
const globalMiddleware = ["check-auth"];

// Load middleware modules dynamically.
const routeMiddleware = resolveMiddleware(
  require.context("@/middleware", false, /.*\.js$/)
);

const router = createNewRouter();

sync(store, router);

export default router;

function createNewRouter() {
  const router = createRouter({
    mode: "history",
    history: createWebHistory(process.env.VUE_APP_BUILD_PATH),
    routes: [...routes, ...adminRoutes],
  });

  searchToken();
  router.beforeEach(beforeEach);

  return router;
}

function searchToken() {
  const token = Cookies.get("token");
  if (token) {
    store.commit("auth/SET_TOKEN", { token: token });
  }
}

async function beforeEach(to, from, next) {
  window.scrollTo(0, 0);
  store.commit("forceCloseModal");
  let components = [];
  let componentsToRoute = to.matched[0].components;
  let component =
    typeof componentsToRoute.default === "function"
      ? await componentsToRoute.default()
      : componentsToRoute.default;
  try {
    components = await resolveComponents([
      component.default ? component.default : component,
    ]);
  } catch (e) {
    console.log(e);
    if (/^Loading( CSS)? chunk (\d)+ failed\./.test(e.message)) {
      window.location.reload(true);
    }
  }

  if (components.length === 0) {
    return next();
  }

  /* const load = components[components.length - 1].loading
  if (load !== undefined || load !== false) {
    router.app.$nextTick(() => router.app.$loading.start())
  } */

  // Get the middleware for all the matched components.
  const middleware = getMiddleware(components);

  // Call each middleware.
  callMiddleware(middleware, to, from, (...args) => {
    // Set the application layout only if "next()" was called with no args.
    /* if (args.length === 0) {
      router.app.setLayout(components[0].layout || '')
    } */

    next(...args);
  });
}

function resolveComponents(components) {
  return Promise.all(
    components.map((component) => {
      return typeof component === "function" ? component() : component;
    })
  );
}

function getMiddleware(components) {
  const middleware = [...globalMiddleware];
  components
    .filter((c) => c.middleware)
    .forEach((component) => {
      if (Array.isArray(component.middleware)) {
        middleware.push(...component.middleware);
      } else {
        middleware.push(component.middleware);
      }
    });

  return middleware;
}

function resolveMiddleware(requireContext) {
  return requireContext
    .keys()
    .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ""), requireContext(file)])
    .reduce(
      (guards, [name, guard]) => ({ ...guards, [name]: guard.default }),
      {}
    );
}

function callMiddleware(middleware, to, from, next) {
  const stack = middleware.reverse();

  const _next = (...args) => {
    // Stop if "_next" was called with an argument or the stack is empty.
    if (args.length > 0 || stack.length === 0) {
      if (args.length > 0) {
        // router.app.$loading.finish()
      }

      return next(...args);
    }

    const middleware = stack.pop();

    if (typeof middleware === "function") {
      middleware(to, from, _next);
    } else if (routeMiddleware[middleware]) {
      routeMiddleware[middleware](to, from, _next);
    } else {
      throw Error(`Undefined middleware [${middleware}]`);
    }
  };

  _next();
}
