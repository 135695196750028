import axios from "axios";
import { breakTextForBreakpoint } from "@/utils";

export const direct_sale = {
  namespaced: true,
  state: () => ({
    groups: [],
    groupsAdmin: [],
    group: null,
    products: {
      data: [],
    },
  }),
  mutations: {
    SET_GROUPS(state, payload) {
      state.groups = payload.data;
    },
    SET_GROUP(state, payload) {
      state.group = payload;
    },
    SET_PRODUCTS(state, payload) {
      state.products = payload;
    },
    SET_GROUPS_ADMIN(state, payload) {
      state.groupsAdmin = payload.map((item) => {
        return {
          ...item,
          break_desc: breakTextForBreakpoint(item.attributes.description),
        };
      });
    },
  },
  actions: {
    async fetchGroupsAdmin({ commit }) {
      try {
        const { data } = (await axios.get("/api/groups-admin")).data;
        commit("SET_GROUPS_ADMIN", data);
        return data;
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async fetchGroups({ commit }) {
      try {
        const { data } = await axios.get("/api/groups");
        commit("SET_GROUPS", data);
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchGroup({ commit }, payload) {
      try {
        const { data } = (await axios.get(`/api/groups/${payload.id}`)).data;
        commit("SET_GROUP", data);
        return data;
      } catch (e) {
        return e;
      }
    },
    async fetchProducts({ commit }, payload) {
      try {
        const { data } = await axios.get(
          `/api/products?page=${payload.page}&status=!0&group=${payload.group_id}&sort=number&per_page=${payload.per_page}`
        );
        commit("SET_PRODUCTS", data);
        return data;
      } catch (e) {
        return e;
      }
    },
  },
  getters: {
    groups: (state) => state.groups,
    groupsAdmin: (state) => state.groupsAdmin,
    group: (state) => state.group,
    products: (state) => state.products,
  },
};
