//Main
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";
import "./config/axios";
import { echo_instance } from "./config/echo";

//Components Third Party
import VueZoomer from "vue-zoomer";
import SplitCarousel from "vue-split-carousel";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueToast from "vue-toast-notification";
import VueSweetalert2 from "vue-sweetalert2";
import VueCountdown from "@chenfengyuan/vue-countdown";
const optionsVueSweetAlert = {
  confirmButtonColor: "#ea7f23",
  cancelButtonColor: "#f56666",
};
import VueLoading from "vue-loading-overlay";
import loadingConfig from "./constants/loadingConfig";

//plugins
import PriceFormatter from "./plugins/PriceFormatter.js";
import DateFormatter from "./plugins/DateFormatter.js";
import FileSource from "./plugins/FileSource.js";
import Slugerize from "./plugins/Slugerize.js";
import CKEditor from "@ckeditor/ckeditor5-vue";

//Icosn
import "./icons/iconsSolid.js";
import "./icons/iconsRegular.js";
import "./icons/iconsBrands.js";

// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
//Css
import "bootstrap/dist/css/bootstrap.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue-loading-overlay/dist/vue-loading.css";
import "vue-toast-notification/dist/theme-sugar.css";

const Vue = createApp(App);

Vue.use(VueZoomer)
  .use(router)
  .use(store)
  .use(PriceFormatter)
  .use(FileSource)
  .use(VueToast)
  .use(Slugerize)
  .use(DateFormatter)
  .use(VueLoading, loadingConfig)
  .use(SplitCarousel)
  .use(CKEditor)
  .use(VueSweetalert2, optionsVueSweetAlert)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component(VueCountdown.name, VueCountdown)
  .mount("#app");

Vue.provide("$echo", echo_instance);
// Color Default template #ea7f23

export default Vue;
