<template>
  <div>
    <router-view :style="[getPrimaryColor]" />
    <transition-group name="showMenu">
      <QuickView
        v-if="$store.getters.modalIsQuickView"
        v-bind="getCurrentQuickViewProduct"
      />
      <!-- <Modal v-if="$store.state.app.currentActiveModal === 'newsletter'" /> -->
    </transition-group>
    <div
      class="modal-background"
      v-if="$store.state.app.currentActiveModal !== ''"
      @click="$store.commit('forceCloseModal')"
    ></div>
    <div class="social" v-if="type">
      <ul>
        <li>
          <router-link class="icon-faq" :to="'/faqs'"
            ><font-awesome-icon
              class="text-white fa-w-20"
              :icon="['fas', 'question']"
            />
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import colors from "./views/color_schemes.json";
import QuickView from "./components/molecules/Modal/QuickView.vue";
// import Modal from "./components/molecules/Modal/NewsLetterPopup.vue";
import { products } from "./data/auctions.json";
export default {
  components: { QuickView },
  created() {
    document.addEventListener(
      "keydown",
      (e) => {
        let key = e.key;
        if (key && "Escape" === key) {
          this.$store.commit("forceCloseModal");
        }
      },
      false
    );

    // if (document.cookie.indexOf("modal=true") === -1) {
    //   setTimeout(
    //     function () {
    //       this.$store.commit("toggleModal", "newsletter");
    //     }.bind(this),
    //     3000
    //   );
    // }
  },
  watch: {
    "$store.state.app.currentActiveModal": function () {
      let body = document.getElementsByTagName("BODY")[0];
      let modalsState = this.$store.state.app.currentActiveModal;
      if (modalsState !== "") {
        body.style.overflow = "hidden";
      } else {
        body.style.overflow = "auto";
      }
    },
  },
  data() {
    return {
      colors,
      type: process.env.VUE_APP_NAME == "casalira" ? false : true,
    };
  },
  computed: {
    getCurrentColor() {
      return process.env.VUE_APP_PRIMARY_COLOR === ""
        ? colors[process.env.VUE_APP_CURRENT_THEME]
        : process.env.VUE_APP_PRIMARY_COLOR;
    },
    getPrimaryColor() {
      return (
        "--primary-color:" +
        this.getCurrentColor +
        ";" +
        "--swiper-theme-color: " +
        this.getCurrentColor +
        ";"
      );
    },
    getCurrentQuickViewProduct() {
      return products.find((prod) => {
        return (
          prod.id == this.$store.state.app.currentActiveModal.split("-")[1]
        );
      });
    },
  },
};
</script>
<style src="./assets/main.css"></style>
<style lang="css" scoped>
.social {
  position: fixed; /* Hacemos que la posición en pantalla sea fija para que siempre se muestre en pantalla*/
  left: -30px; /* Establecemos la barra en la izquierda */
  top: 50vh; /* Bajamos la barra 200px de arriba a abajo */
  z-index: 2000; /* Utilizamos la propiedad z-index para que no se superponga algún otro elemento como sliders, galerías, etc */
}
.social ul {
  list-style: none;
}

.social ul li a {
  display: inline-block;
  color: #fff;
  background: #014aa2;
  padding: 10px 15px;
  text-decoration: none;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; /* Establecemos una transición a todas las propiedades */
}

.social ul li .icon-faqs {
  color: #fff !important;
  background: #014aa2;
}

.social ul li a:hover {
  color: #fff !important;
  background: #014aa2; /* Cambiamos el fondo cuando el usuario pase el mouse */
  padding: 10px 30px; /* Hacemos mas grande el espacio cuando el usuario pase el mouse */
}

@media screen and (min-width: 150px) {
  .social ul li a {
    padding: 10px 15px;
  }
  .social ul li a:hover {
    padding: 10px 30px;
  }
}

@media screen and (max-width: 501px) {
  .social ul li a {
    padding: 10px 25px;
  }
  .social ul li a:hover {
    padding: 10px 40px;
  }
}
</style>
