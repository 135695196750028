export const application = {
  state: () => ({
    nameApp: process.env.VUE_APP_NAME,
    breadcrumb: [],
    contactInfo: {
      street: "Av. Irarrázaval 1372 Ñuñoa.",
      email:
        process.env.VUE_APP_NAME == "casalira"
          ? "contacto@casalira.com"
          : "contacto@rematelasamericas.com",
      phoneNo: "+56 994 779195",
      socials: [
        { icon: "facebook-f", link: "https://www.facebook.com/Remates-Las-Americas-112319751445719" },
        { icon: "twitter", link: "https://twitter.com/RematesRLA" },
       /*  { icon: "linkedin", link: "/" }, */
        { icon: "instagram", link: "https://www.instagram.com/rematesrla/" },
      ],
    },
    settings: {
      websiteName: "Casalira",
    },
    locale: "en-US",
    currency: "USD",
    currentActiveModal: "",
  }),
  mutations: {
    setLocation(state, payload) {
      state.breadcrumb = payload;
    },
    toggleModal(state, payload) {
      if (state.currentActiveModal === payload) {
        state.currentActiveModal = "";
      } else {
        state.currentActiveModal = payload;
      }
    },
    forceCloseModal(state) {
      state.currentActiveModal = "";
    },
  },

  actions: {},
  getters: {
    nameApp: (state) => {
      return state.nameApp;
    },
    activeModal: (state) => {
      return state.currentActiveModal;
    },
    modalIsQuickView: (state) => {
      return state.currentActiveModal.includes("qv");
    },
    modalIsGallery: (state) => {
      return state.currentActiveModal.includes("gallery");
    },
    modalIsGeneral: (state) => {
      return state.currentActiveModal.includes("general");
    },
    appIsLasAmericas: (state) => {
      return state.nameApp !== "casalira";
    },
  },
};
