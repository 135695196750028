import axios from "axios";
import { breakTextForBreakpoint } from "@/utils";

export const auction = {
  namespaced: true,
  state: () => ({
    auctions: {
      data: [],
    },
    auction: {
      attributes: { date: null, streaming_url: null },
      relationships: {
        comuna: { attributes: {} },
        lotes: [],
        exhibition: {},
      },
    },
    exhibition: null,
  }),
  mutations: {
    SET_AUCTIONS(state, auctions) {
      state.auctions = auctions;
    },
    SET_AUCTION(state, auction) {
      state.auction = auction;
      state.exhibition = auction.relationships.exhibition;
      if (state.auction.relationships.lotes.data?.length) {
        state.auction.relationships.lotes.data =
          state.auction.relationships.lotes.data.map((item) => {
            item.attributes.break_desc = breakTextForBreakpoint(
              item.attributes.description
            );
            return {
              ...item,
              showDescription: false,
              expanded: false,
            };
          });
      }
    },
    SET_STATUS_AUCTION(state, status) {
      const status_description =
        status == 1 ? "Activo" : status == 4 ? "Pausado" : "Cerrado";

      if (state.auction) {
        state.auction.attributes.status_number = status;
        state.auction.attributes.status = status_description;
      }
    },
    SET_ACTIVE_LOT(state, lot) {
      //Activamos el primer lote del lado del cliente, para las tablas
      let lots_updated = state.auction.relationships.lotes.data.map(
        (lot_loop) => {
          if (lot_loop.attributes.number === lot.attributes.number) {
            lot_loop.attributes.status = 2;
          }
          return lot_loop;
        }
      );
      state.auction.relationships.lotes.data = lots_updated;
    },
    SET_AWARD_LOT(state) {
      //Adjudicar el lote del lado del cliente para actualizar las tablas
      let lots_updated = state.auction.relationships.lotes.data.map(
        (lot_loop) => {
          if (lot_loop.attributes.status === 2) {
            lot_loop.attributes.status = 3;
          }
          return lot_loop;
        }
      );
      state.auction.relationships.lotes.data = lots_updated;
    },
    UPDATE_STREAMING_URL_AUCTION(state, payload) {
      state.auction.attributes.streaming_url = payload;
    },
  },
  actions: {
    updateStreamingUrlAuction({ commit }, payload) {
      return commit("UPDATE_STREAMING_URL_AUCTION", payload);
    },
    async fetchAuctions({ commit }, { status }) {
      const options = status
        ? {
            params: {
              status,
            },
          }
        : {};
      try {
        const { data } = await axios.get(`/api/auctions?status`, options);
        commit("SET_AUCTIONS", data);
      } catch (e) {
        return e;
      }
    },

    async fetchAuction({ commit }, auction_slug) {
      try {
        const { data } = (await axios.get(`/api/auctions/${auction_slug}`))
          .data;
        commit("SET_AUCTION", data);
        return data;
      } catch (e) {
        return e;
      }
    },

    async storeStreamingUrl({ commit }, { auction_slug, streaming_url }) {
      try {
        console.log(commit);
        const { message } = (
          await axios.post(`/api/auctions/${auction_slug}/url`, {
            _method: "PATCH",
            url: streaming_url,
          })
        ).data;
        return message;
      } catch (e) {
        return e;
      }
    },

    async updateStatusAuction(
      { commit },
      { auction_slug, status, resume_auction }
    ) {
      console.log(commit);
      try {
        const { auction } = (
          await axios.post(`/api/auctions/${auction_slug}/update-status`, {
            status,
            resume_auction,
          })
        ).data;
        return auction;
      } catch (e) {
        return e;
      }
    },
    commitNewStatus({ commit }, status) {
      return commit("SET_STATUS_AUCTION", status);
    },
    async isParticipantAuction({ commit }, payload) {
      console.log(commit);
      try {
        const { data } = await axios.get(
          `/api/auctions/${payload.auction_id}/participants/${payload.user_id}`
        );
        return data;
      } catch ({ response }) {
        return response;
      }
    },
    async sendRequestForParticipationAuction({ commit }, payload) {
      try {
        console.log(commit);
        const { data } = await axios.post(
          `/api/auctions/${payload.auction_id}/participants`,
          {
            users: [payload.user_id],
          }
        );
        return data;
      } catch (e) {
        return e;
      }
    },
  },
  getters: {
    auctions: (state) => state.auctions,
    auction: (state) => state.auction,
    exhibition: (state) => state.exhibition,
    status: (state) => state.auction?.attributes?.status,
  },
};
