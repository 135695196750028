import axios from "axios";

export const tool = {
  namespaced: true,
  state: () => ({
    comunas: [],
    configurations: [],
    warranty_amounts: [],
  }),
  mutations: {
    SET_COMUNAS(state, payload) {
      state.comunas = payload.map((item) => {
        return {
          id: item.id,
          name: item.attributes.name,
        };
      });
    },
    SET_CONFIGURATIONS(state, payload) {
      state.configurations = payload;
    },
    SET_WARRANTY_AMOUNTS(state, payload) {
      state.warranty_amounts = payload;
    },
  },
  actions: {
    async fetchComunas({ commit }) {
      try {
        const { data } = (await axios.get("/api/comunas")).data;
        commit("SET_COMUNAS", data);
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    async fetchConfigurations({ commit }) {
      try {
        const { data } = await axios.get(`/api/configurations`);
        commit("SET_CONFIGURATIONS", data);
      } catch (e) {
        return e;
      }
    },
    async fetchWarrantyAmounts({ commit }) {
      try {
        const { data } = await axios.get("/api/warranty_amounts");
        commit("SET_WARRANTY_AMOUNTS", data);
      } catch (error) {
        return error;
      }
    },
    async sendMessageContact({ commit }, payload) {
      try {
        console.log(commit);
        const { message } = (await axios.post("/api/contact/message", payload))
          .data;
        return { message };
      } catch (error) {
        return error;
      }
    },
  },
  getters: {
    comunas: (state) => state.comunas,
    configurations: (state) => state.configurations,
    warranty_amounts: (state) => state.warranty_amounts,
  },
};
