import { application } from "./applicationState.js";
import { wishlist } from "./wishlist.js";
import { cart } from "./cart.js";
import { user } from "./user.js";
import { auth } from "./app/auth.js";
import { tool } from "./app/tool.js";
import { direct_sale } from "./app/directSale";
import { auction } from "./app/auction";
import { lot } from "./app/lot";
import { product } from "./app/product";
import { userApp } from "./app/user";
import { search } from "./app/search";
import { currency } from "./app/currency.js";

import createPersistedState from "vuex-persistedstate";

export const dataState = createPersistedState({
  paths: [
    "user",
    "application",
    "cart",
    "wishlist",
    "auth",
    "tool",
    "direct_sale",
    "auction",
    "product",
    "userApp",
    "lot",
    "search",
    "currency"
  ],
});

export const modules = {
  app: application,
  wishlist,
  cart,
  user,
  auth,
  tool,
  direct_sale,
  auction,
  product,
  userApp,
  lot,
  search,
  currency
};
