import axios from "axios";

export const search = {
  namespaced: true,
  state: () => ({
    search: null,
    items: [],
  }),
  mutations: {
    SET_SEARCH: (state, payload) => (state.search = payload),
    SET_DATA_RESULTS: (state, payload) => {
      return (state.items = payload.map((item) => {
        return {
          ...item,
          url_route:
            item.type === "auctions"
              ? { name: "AuctionShow", params: { slug: item.id } }
              : { name: "DirectSaleProduct", params: { id: item.id } },
        };
      }));
    },
  },
  actions: {
    async searchData({ commit, state }) {
      if (state.search) {
        try {
          const { data } = (await axios.get(`/api/search?key=${state.search}`))
            .data;
          commit("SET_DATA_RESULTS", data);
          return true;
        } catch (e) {
          return e;
        }
      }
    },
    updateSearch({ commit }, payload) {
      return commit("SET_SEARCH", payload);
    },
  },
  getters: {
    itemsResults: (state) => state.items,
    search: (state) => state.search,
  },
};
