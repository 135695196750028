import axios from "axios";
import Swal from "sweetalert2";

export const userApp = {
  namespaced: true,
  state: () => ({
    lots: [],
    auctions: [],
    acreditProducts: [],
    allProducts: [],
    users: [],
  }),
  mutations: {
    SET_LOTS_USER(state, lots) {
      const data = lots.map((lot) => {
        return {
          ...lot,
          bid: {
            amount: parseInt(lot.bid.amount).toLocaleString(),
            count: lot.bid.count,
            date: lot.bid.date,
          },
        };
      });
      state.lots = data;
    },
    SET_AUCTIONS_USER(state, auctions) {
      state.auctions = auctions;
    },
    SET_ACREDIT_PRODUCTS(state, acreditProducts) {
      const data = acreditProducts.map((product) => {
        return {
          ...product,
          amount: parseInt(product.amount).toLocaleString(),
          offer: {
            amount: parseInt(product.offer.amount).toLocaleString(),
            offered: product.offer.offered,
          },
        };
      });
      state.acreditProducts = data;
    },
    SET_ALL_PRODUCTS(state, allProducts) {
      const data = allProducts.map((product) => {
        return {
          ...product,
          amount: parseInt(product.amount).toLocaleString(),
          offer: {
            amount: parseInt(product.offer.amount).toLocaleString(),
            offered: product.offer.offered,
          },
        };
      });
      state.allProducts = data;
    },
    SET_USERS(state, users) {
      state.users = users.data;
    },
  },
  actions: {
    async fetchAllUsers({ commit }) {
      try {
        const { data } = await axios.get("/api/users");
        commit("SET_USERS", data);
        return data;
      } catch (e) {
        return e;
      }
    },
    async updateUser(context, payload) {
      console.log(context);
      const { user, info, userId } = payload;
      let url = `/api/users/${userId}`;
      let method = "patch";
      try {
        const { data } = await axios[method](url, {
          user: user,
          info: info,
        });
        return data;
      } catch (e) {
        const { status } = e.response || {};
        if (status === 422) {
          const { errors } = e.response.data;
          const err = errors[Object.keys(errors)[0]][0];
          Swal.fire({
            icon: "error",
            title: "Disculpe",
            text: err,
            showConfirmButton: false,
            timer: 4000,
          });
        }
      }
    },
    async deleteUser({ commit }, id) {
      console.log(commit);
      try {
        const { data } = await axios.delete(`/api/users/${id}`);
        return data;
      } catch (e) {
        return e;
      }
    },
    async fetchLotsUser({ commit }) {
      try {
        const { data } = (await axios.get(`/api/user/lots/accredited`)).data;
        commit("SET_LOTS_USER", data);
        return data;
      } catch (e) {
        return e;
      }
    },
    async fetchAuctionsUser({ commit }) {
      try {
        const { data } = (await axios.get(`/api/user/auctions`)).data;
        commit("SET_AUCTIONS_USER", data);
        return data;
      } catch (e) {
        return e;
      }
    },
    async fetchUserProducts({ commit }, type) {
      try {
        const { data } = await axios.get(`/api/user/products?type=${type}`);
        commit(type === 2 ? "SET_ACREDIT_PRODUCTS" : "SET_ALL_PRODUCTS", data);
        return data;
      } catch (e) {
        return e;
      }
    },
  },
  getters: {
    users: (state) => state.users,
    lots: (state) => state.lots,
    auctions: (state) => state.auctions,
    acreditProducts: (state) => state.acreditProducts,
    allProducts: (state) => state.allProducts,
  },
};
