import axios from "axios";
import route from "@/router";
import $store from "@/store";

if (process.env.NODE_ENV === "production" && process.env.VUE_APP_API_URL) {
  axios.defaults.baseURL = process.env.VUE_APP_API_URL;
} else {
  axios.defaults.baseURL =
    process.env.VUE_APP_API_DEV || "http://127.0.0.1:8000";
}

// Config Before Request
axios.interceptors.request.use(
  (request) => {
    const token = $store.getters["auth/token"];
    if (token) {
      request.headers.common.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const { status } = error.response;
      if (status === 401) {
        if ($store.getters["auth/check"]) {
          // Se ha vencido su sesión
          $store.commit("auth/LOGOUT");
          route.push({ name: "MyAccount" });
        }
      }
    } else {
      // alert('No hemos podido establecer conexión con el servidor. \nSi tiene un bloqueador de publicidad deshabilitelo.')
    }
    return Promise.reject(error);
  }
);
