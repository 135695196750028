import { createStore } from "vuex";
import { modules, dataState } from "./storeModules.js";

const store = new createStore({
  modules,
  strict: process.env.NODE_ENV !== "production",
  plugins: [dataState]
});

export default store;
