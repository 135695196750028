import axios from "axios";

export const currency = {
namespaced: true,
state: () => ({
  currencys: null
}),

 mutations: {
  SET_CURRENCYS(state, currencys) {
    state.currencys = currencys;
  }
},

actions: {
  async fetchCurrencys({ commit }) {
    try {
      const { data } = (await axios.get(`/api/currencys`)).data;
      commit("SET_CURRENCYS", data);
      // eslint-disable-next-line
    } catch (e) {}
  }
},

getters: {
  currencys: state => state.currencys
}
}
