import axios from "axios";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

export const auth = {
  namespaced: true,
  state: () => ({
    user: null,
    token: null,
  }),
  mutations: {
    SET_TOKEN(state, { token, remember = null }) {
      state.token = token;
      Cookies.set("token", token, {
        expires: remember ? 365 : 7,
        sameSite: "Lax",
      });
    },

    SET_USER(state, user) {
      state.user = user;
    },
    FETCH_USER_FAILURE(state) {
      state.token = null;
      Cookies.remove("token");
    },

    LOGOUT(state) {
      Cookies.remove("token");
      state.user = null;
      state.token = null;
    },
  },
  actions: {
    async loginUser({ commit }, payload) {
      try {
        const data = (await axios.post("/api/auth/login", payload)).data;
        commit("SET_TOKEN", {
          token: data.meta.token,
          remember: true,
        });
        commit("SET_USER", data.data);
        return data;
      } catch (e) {
        if (e.response) {
          Swal.fire({
            title: "Error!",
            text: "Usuario no autenticado. Credenciales incorrectas",
            icon: "error",
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#DD6B55",
          });
          return e.response.status;
        }
        return e;
      }
    },
    async registerUser(context, payload) {
      console.log(context);
      let url = "/api/auth/register";
      let method = "post";
      try {
        const { data } = await axios[method](url, {
          user: payload.user,
          info: payload.info,
        });
        return data;
      } catch (e) {
        const { status } = e.response || {};
        if (status === 422) {
          const { errors } = e.response.data;
          const err = errors[Object.keys(errors)[0]][0];
          Swal.fire({
            icon: "error",
            title: "Disculpe",
            text: err,
            showConfirmButton: false,
            timer: 4000,
          });
        }
      }
    },
    async fetchUser({ commit }) {
      try {
        const { data } = (await axios.get("/api/users/me")).data;
        commit("SET_USER", data);
      } catch (e) {
        console.log(
          "Ha ocurrido un error al intentar hacer fetch del usuario autenticado."
        );
        commit("SET_USER", null);
        commit("FETCH_USER_FAILURE");
      }
    },
    async sendResetPassword({ commit }, payload) {
      try {
        console.log(commit);
        const resp = await axios.post("/api/auth/reset-password", payload);
        return resp;
      } catch (e) {
        console.log(
          "Ha ocurrido un error al intentar reestablecer la contraseña."
        );
        return e;
      }
    },

    async logout({ commit }) {
      try {
        await axios.post("/api/logout");
        commit("LOGOUT");
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    user: (state) => state.user,
    token: (state) => state.token,
    check: (state) => state.user !== null && state.user !== undefined,
    role: (state) => (state.user ? state.user.attributes.role : null),
    offers: (state) => (state.user ? state.user.relationships.products : []),
  },
};
