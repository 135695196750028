// Config laravel-echo-server
import store from "@/store";
import Echo from "laravel-echo";
const io = require("socket.io-client");

window.io = io;

const token = store.getters["auth/token"];

export const echo_instance = new Echo({
  broadcaster: "socket.io",
  host: `${window.location.hostname}:2053`,
  auth: {
    headers: {
      Authorization: "Bearer " + token,
    },
  },
});
