import breakpoints from "@/constants/breakpoints";

const validateRut = (rut) => {
  const { validate } = require("rut.js");
  const valid = validate(rut);
  return valid;
};

const breakTextForBreakpoint = (text) => {
  let responsive = window.screen.width;
  let textBreak = "";
  let desc = text;
  breakpoints.forEach((point) => {
    if (responsive >= point.respon) {
      if (desc.length > point.letters)
        textBreak = `${desc.slice(0, point.letters)}...`;
      else textBreak = desc.slice(0, point.letters);
      if (desc.length == 0) textBreak = desc;
    }
  });
  return textBreak;
};

const getNestedValue = (obj, path, fallback) => {
  const last = path.length - 1;

  if (last < 0) return obj === undefined ? fallback : obj;

  for (let i = 0; i < last; i++) {
    if (obj == null) {
      return fallback;
    }
    obj = obj[path[i]];
  }

  if (obj == null) return fallback;

  return obj[path[last]] === undefined ? fallback : obj[path[last]];
};

const getObjectValueByPath = (obj, path, fallback) => {
  // credit: http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key#comment55278413_6491621
  if (obj == null || !path || typeof path !== "string") return fallback;
  if (obj[path] !== undefined) return obj[path];
  path = path.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  path = path.replace(/^\./, ""); // strip a leading dot
  return getNestedValue(obj, path.split("."), fallback);
};

export { validateRut, breakTextForBreakpoint, getObjectValueByPath };
