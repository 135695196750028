import axios from "axios";
import Swal from "sweetalert2";

export const product = {
  namespaced: true,
  state: () => ({
    productAction: null,
    currentAmountProduct: null,
    product: null,
    productsAdmin: [],
    productsSoldAdmin: [],
  }),
  mutations: {
    SET_PRODUCT_ACTION(state, productAction) {
      state.productAction = productAction;
    },
    SET_CURRENT_AMOUNT_PRODUCT(state, amount) {
      state.currentAmountProduct = amount;
    },
    SET_PRODUCT(state, payload) {
      state.product = payload;
    },
    SET_PRODUCTS_ADMIN(state, payload) {
      state.productsAdmin = payload;
    },
    SET_PRODUCTS_SOLD_ADMIN(state, payload) {
      state.productsSoldAdmin = payload;
    },
  },
  actions: {
    async fetchProductsAdmin({ commit }) {
      try {
        const { data } = (
          await axios.get(`/api/products?status=!2&per_page=2000&sort=number`)
        ).data;
        commit("SET_PRODUCTS_ADMIN", data);
        return data;
      } catch (e) {
        return e;
      }
    },
    async fetchProductsSoldAdmin({ commit }) {
      try {
        const { data } = (
          await axios.get(`/api/products?status=2&per_page=2000&sort=number`)
        ).data;
        commit("SET_PRODUCTS_SOLD_ADMIN", data);
        return data;
      } catch (e) {
        return e;
      }
    },
    async fetchProduct({ commit }, { id }) {
      try {
        const { data } = (await axios.get(`/api/products/${id}`)).data;
        commit("SET_PRODUCT", data);
        return data;
      } catch (e) {
        return e;
      }
    },
    async sendOfferProduct({ commit }, { id, payload }) {
      try {
        console.log(commit);
        const { data } = await axios.post(
          `/api/products/${id}/offers`,
          payload
        );
        return data;
      } catch (e) {
        if (e.response) {
          if (e.response.status === 409) {
            Swal.fire({
              icon: "warning",
              title: "¡Disculpe!",
              text: e.response.data.errors.detail,
              confirmButtonColor: "#3085d6",
            });
          }
        }
        return e;
      }
    },
  },
  getters: {
    product: (state) => state.product,
    productsAdmin: (state) => state.productsAdmin,
    productsSoldAdmin: (state) => state.productsSoldAdmin,
    productAction: (state) => state.productAction,
    currentAmountProduct: (state) => state.currentAmountProduct,
  },
};
