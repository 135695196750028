export default [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(
        /* webpackChunkName: "shop" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/Home.vue"
      ),
  },
  //General
  {
    path: "/search",
    name: "Search",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/Search.vue"
      ),
  },
  {
    path: "/my-account",
    name: "MyAccount",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "myaccount" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/auth/MyAccount.vue"
      ),
  },
  {
    path: "/register",
    name: "Register",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "myaccount" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/auth/Register.vue"
      ),
  },
  {
    path: "/password/reset",
    name: "PasswordReset",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "myaccount" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/auth/PasswordReset.vue"
      ),
  },
  {
    path: "/password/reset/:token",
    name: "PasswordForgot",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "myaccount" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/auth/PasswordForgot.vue"
      ),
  },
  {
    path: "/contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/Contact.vue"
      ),
  },
  {
    path: "/faqs",
    name: "FAQ",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/FAQs.vue"
      ),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/About.vue"
      ),
  },
  {
    path: "/404",
    name: "404",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/NotFound.vue"
      ),
  },
  {
    path: "/profile",
    name: "Profile",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/profile/index.vue"
      ),
  },
  {
    path: "/edit-profile",
    name: "EditProfile",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/profile/edit.vue"
      ),
  },
  // User Auctions
  {
    path: "/user/auctions",
    name: "user-auctions",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/user/auctions.vue"
      ),
  },
  // User direct sale
  {
    path: "/user/direct-sale",
    name: "user-direct-sale",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/user/direct_sale.vue"
      ),
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/TermsAndConditions.vue"
      ),
  },
  //Direct Sale
  {
    path: "/direct-sale",
    name: "DirectSale",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/direct-sale/index.vue"
      ),
  },
  {
    path: "/direct-sale/group/:id",
    name: "DirectSaleGroup",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/direct-sale/Group.vue"
      ),
  },
  {
    path: "/direct-sale/product/:id",
    name: "DirectSaleProduct",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/direct-sale/Product.vue"
      ),
  },
  //Auctions
  {
    path: "/auctions",
    name: "Auctions",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/auctions/index.vue"
      ),
  },
  {
    path: "/auctions/show/:slug",
    name: "AuctionShow",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/auctions/Auction.vue"
      ),
  },
  {
    path: "/auctions/:slug",
    name: "auction-playground",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/auctions/PlaygroundUser.vue"
      ),
  },
  { path: "/:catchAll(.*)", redirect: "/404" },
];
