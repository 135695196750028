export default [
  {
    path: "/admin/products",
    name: "AdminProducts",
    // route level code-splitting
    // this generates a separate chunk (shop.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/admin/products/index.vue"
      ),
  },
  {
    path: "/admin/product/show/:id",
    name: "admin-product-show",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/admin/products/show.vue"
      ),
  },
  {
    path: "/admin/product/edit/:id",
    name: "admin-product-edit",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/admin/products/edit.vue"
      ),
  },
  {
    path: "/admin/product/new",
    name: "admin-product-new",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/admin/products/new.vue"
      ),
  },
  {
    path: "/auctions/admin/:slug",
    name: "auction-playground-admin",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/admin/auctions/PlaygroundAdmin.vue"
      ),
  },
  {
    path: "/admin/auctions",
    name: "admin-auctions",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/admin/auctions/index.vue"
      ),
  },
  {
    path: "/admin/auctions/show/:slug",
    name: "admin-auction-show",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/admin/auctions/show.vue"
      ),
  },
  {
    path: "/admin/auctions/new",
    name: "admin-auctions-new",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/admin/auctions/new.vue"
      ),
  },
  {
    path: "/admin/auctions/edit/:id",
    name: "admin-auctions-edit",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/admin/auctions/edit.vue"
      ),
  },
  //Admin Lotes
  {
    path: "/admin/auctions/:slug/lots/new",
    name: "admin-auctions-lots-new",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/admin/auctions/lots/new.vue"
      ),
  },
  {
    path: "/admin/auctions/:slug/lots/show/:id",
    name: "admin-auctions-lots-show",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/admin/auctions/lots/show.vue"
      ),
  },
  {
    path: "/admin/auctions/:slug/lots/order-lots",
    name: "admin-auctions-lots-order",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/admin/auctions/lots/order.vue"
      ),
  },
  {
    path: "/admin/auctions/:slug/lots/edit/:id",
    name: "admin-auctions-lots-edit",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/admin/auctions/lots/edit.vue"
      ),
  },
  {
    path: "/admin/groups/show/:id",
    name: "admin-groups-show",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/admin/products/groups/show.vue"
      ),
  },
  {
    path: "/admin/groups/edit/:id",
    name: "admin-groups-edit",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/admin/products/groups/edit.vue"
      ),
  },
  {
    path: "/admin/groups/new",
    name: "admin-groups-new",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/admin/products/groups/new.vue"
      ),
  },
  //Users
  {
    path: "/admin/users",
    name: "admin-users",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/admin/users/index.vue"
      ),
  },
  {
    path: "/admin/users/show/:id",
    name: "admin-users-show",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/admin/users/show.vue"
      ),
  },
  {
    path: "/admin/users/edit/:id",
    name: "admin-users-edit",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/" +
          process.env.VUE_APP_CURRENT_THEME +
          "/admin/users/edit.vue"
      ),
  },
];
